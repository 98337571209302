<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">考勤规则</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">搜索</div>
					<div class="inputs_box">
						<el-input clearable="" type="text" placeholder="考勤规则名称" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>
		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="$router.push({path:'Attendance_rules_set'})">添加考勤规则</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<el-table-column prop="title" label="规则名称" min-width="120"></el-table-column>
					<el-table-column prop="departList" label="部门" min-width="120">
						<template slot-scope="scope">
							<div class="time-list">
								<div class="time-item" v-for="(time_item,index) in scope.row.departList">
									{{time_item.title}}
								</div>
							</div>
						</template>
					</el-table-column>

					<!-- <el-table-column prop="cus_type" label="考勤类型" min-width="120"></el-table-column> -->
					<!-- <el-table-column prop="number" label="人数" min-width="120"></el-table-column> -->
					<el-table-column prop="cus_time_range" label="考勤时间" min-width="200">
						<template slot-scope="scope">
							<div class="time-list">
								<div class="time-item" v-for="(time_item,index) in scope.row.time_arr">
									{{time_item}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="startDay" label="生效时间" min-width="120"></el-table-column>
					<el-table-column prop="name" label="操作" min-width="120">
						<template slot-scope="scope">
							<el-button style="margin-right: 10px" @click="do_row_edit(scope.row)" type="text">编辑排班</el-button>
							<!-- <el-popconfirm confirm-button-text="确认" cancel-button-text="不了" icon="el-icon-info" icon-color="red"
								title="确定删除吗?" @confirm="handleClick('删除', scope.row)">
								<el-button slot="reference" type="text">删除</el-button>
							</el-popconfirm> -->
							<el-button type="text" @click="do_row_delete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson="count" @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import page from '@/components/page/page.vue'; //分页  统一的

	export default {
		components: {
			page
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据

				list: [

				],
				forms: {
					type: 1,
					keyword: '',
				},
				pages: {
					page: 1,
					limit: 10
				},
				count: 0,
				times: [], //时间范围

				//

			};
		},
		computed: {},
		watch: {
			times(value) {
				console.log('value', value);
			}
		},

		created() {
			this.page_title = this.$route.meta.title;
			this.get_list()
		},
		mounted() {},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page);
				this.pages.page = page;
				this.get_list();
			},
			// 接收条数
			row(row) {
				console.log(row);
				this.pages.limit = row;
				this.get_list();
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e);
				this.check_list = e;
			},
			//搜素
			handle_search() {
				this.pages.page = 1;
				this.get_list();
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list();
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms);
				this.loading = true;
				this.$api(
					'kaoqin/getRuleList', {
						// mobile:this.mobile
						...this.forms,
						...this.pages
					},
					'get'
				).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.count = res.data.count;

						let list = res.data.list;
						list.forEach(v => {
							let cardRules = v.info.cardRules || []
							if (cardRules.length) {
								// let item = v.info.cardRules[0];
								// v.cus_time_range = item.startHour + ' - ' + item.endHour;
								let time_arr = []
								cardRules.forEach(item => {
									time_arr.push(item.startHour + ' - ' + item.endHour)
								})
								
								v.cus_time_range = time_arr.join(',')
								v.time_arr = time_arr
							}
						})
						this.list = list;


					} else {
						alertErr(res.msg);
					}
				});
			},

			do_export() {},


			do_row_edit(row) {
				this.$log('考勤规则', row)
				// this.$refs.account_add_modal.init(row)
				this.$router.push({
					path: `/Attendance_settings/Attendance_rules_set?id=${row.id}`,
				})
			},
			do_row_delete(row) {
				this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("kaoqin/delRule", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {

				});
			},

		}
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #ffffff;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #e8e8e8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;
						}
					}

					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ffffff;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
						}
					}
				}
			}
		}

		.bom_box {
			background: #ffffff;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #ffffff;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ffffff;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
						}
					}
				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}
		}
	}
</style>